import React from 'react';
import { connect } from 'react-redux';

import Container from './container';
import cartActions from '../actions/cart';
import Layout from './join_bundle_layout';
import { StudentTracker } from '../services/StudentTracker';
import LocalStorageManager from '../../../lib/localStorageManager';

function BundleShoppingCartHeader(props) {
  const { bundleName, i18n } = props;

  return (
    <div>
      <h3 className="text-thin">{i18n.courseTitle}</h3>
      <p className="sub-heading shift-left" dangerouslySetInnerHTML={{__html: i18n.paragraph.replace('%{course_name}', bundleName)}}></p>
    </div>
  );
}

function SchoolShoppingCartHeader(props) {
  return (
    <div>
      <h3 className="text-thin" dangerouslySetInnerHTML={{__html: props.i18n.schoolTitle.replace('%{course_name}', props.bundleName)}}></h3>
    </div>
  );
}

function ternary(key, ComponentYes, ComponentNo) {
  return function (props) {
    if (props[key]) {
      return <ComponentYes {...props} />;
    } else {
      return <ComponentNo {...props} />;
    }
  };
}

const ShoppingCartHeader = ternary('isExplicitSchool', SchoolShoppingCartHeader, BundleShoppingCartHeader);

const ShoppingCartPage = connect(mapStateToProps)(class ShoppingCartPage extends Container {
  checker = null;

  static subheader = 'simple';

  static propTypes = {
    dispatch: React.PropTypes.func,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.error){
      if ((this.props.bundleAdded === true || this.props.isExplicitSchool) && this.props.finalPrice > 0){
        this.trackAddedToCart(this.props);
        LocalStorageManager.save('couponName', this.props.couponName);
        LocalStorageManager.save('couponDiscount', this.props.couponDiscount);
        LocalStorageManager.save('finalPrice', this.props.finalPrice);
      }
    }
  }

  triggerRemove(id) {
    this.props.dispatch(cartActions.removeFromCart(id));
  }

  renderPayNow() {
    const { form, finalPrice } = this.props;

    if (finalPrice === 0) {
      return this.renderRedeemCouponButton();
    } else if (form) {
      return this.renderPayNowForm();
    } else {
      return this.renderPayNowButton();
    }
  }

  payWithCoupon(e) {
    e.stopPropagation();
    this.props.dispatch(cartActions.updateCouponOrder());
  }

  renderRedeemCouponButton() {
    return (
      <a
        href="#"
        aria-label={this.props.i18n.couponButtonAriaLabel}
        className="button button-primary button-w-tip"
        data-testname="join-with-coupon"
        onClick={(e) => this.payWithCoupon(e)}
      >
        <span className="icon icon-lock-2"></span>
        {this.props.i18n.couponButton}
      </a>
    );
  }

  renderPayNowButton() {
    const { payNowUrl, i18n } = this.props;
    return (
      <a href={payNowUrl} aria-label={i18n.payButtonAriaLabel} className="button button-primary button-w-tip" onClick={(e) => this.validatePayNowButton(e)}>
        <span className="icon icon-lock-2"></span>
        {i18n.payButton}
      </a>
    );
  }

  renderPayNowForm() {
    const { form, i18n } = this.props;

    return (
      <div>
        <form id='paymentForm'></form>
        <button aria-label={i18n.payButtonAriaLabel} className='button button-primary button-w-tip checkout-button' onClick={(e) => this.processOrder(e)} >
          <span className="icon icon-lock-2"></span>
          {i18n.payButton}
        </button>
      </div>
    );
  }

  validatePayNowButton(e) {
    e.preventDefault();
    e.target.classList.add('nm-btn', 'disabled');
    e.target.style.pointerEvents = 'none';

    this.trackViewCart(this.props);

    const { orderId, finalPrice, payNowUrl } = this.props;
    this.props.dispatch(cartActions.checkOrderValid(orderId, finalPrice))
        .then((value) => {
          if(value.order.sum == finalPrice){
            this.props.dispatch(cartActions.processOrder(orderId));
            location.href = payNowUrl;
          }else{
            location.href = location.href.split('?')[0] + `?from=${finalPrice}&to=${value.order.sum}`;
          }
        });
  }

  processOrder(e) {
    e.target.classList.add('nm-btn', 'disabled');
    e.target.style.pointerEvents = 'none';

    const { orderId, finalPrice } = this.props;
    this.props.dispatch(cartActions.checkOrderValid(orderId, finalPrice))
        .then(function (value) {
           if(value.order.sum == finalPrice){
             this.creatAndSubmitDynamicForm();
           }else{
            location.href = location.href.split('?')[0] + `?from=${finalPrice}&to=${value.order.sum}`;
           }
        }.bind(this))
  }

  creatAndSubmitDynamicForm() {
    this.trackViewCart(this.props);

    const { form, orderId } = this.props;
    var dynamicForm = document.getElementById("paymentForm");
    dynamicForm.setAttribute("method", "post");
    dynamicForm.setAttribute("action", form.get('url'));

    var fields = form.get('fields')
    fields.map((v, k) => {
      var field = document.createElement("input")
      field.setAttribute("type", "text")
      field.setAttribute("name", k)
      field.setAttribute("value", v)
      field.setAttribute("type", "hidden")
      dynamicForm.appendChild(field)
    })
    this.props.dispatch(cartActions.processOrder(orderId));
    dynamicForm.submit();
  }

  trackAddedToCart = (props) => {
    StudentTracker.track('add_to_cart', {
      'value': props.finalPrice,
      'currency': 'ILS',
      'items': this.itemProps(props)
    });
  }

  itemProps = (props) => {
    let items = [
      {
        'item_id': `${props.bundleId}`,
        'item_name': props.bundleName,
        'item_brand': props.teacherName,
        'item_brand_id': props.teacherId,
        'item_category': props.schoolName,
        'item_category2': props.bundlePolicy,
        'coupon': props.couponName,
        'currency': "ILS",
        'discount': props.discount,
        'price': props.finalPrice,
        'quantity': 1
      }
    ];
    return items;
  }

  trackViewCart = (props) => {
    StudentTracker.track('view_cart', {
      'value': props.finalPrice,
      'currency': 'ILS',
      'items': this.itemProps(props)
    });
  }

  render() {
    const { finalPrice, discount, currencyType, priceBeforeDiscount, isExplicitSchool, i18n } = this.props;

    const iconedBlockStyle = isExplicitSchool ? {
      paddingRight: 0,
    } : {
      paddingRight: '15px',
    };

    return (
      <div className="content-inner thankyou-block" style={{ paddingRight: 0 }}>
        <div className="iconed-block thankyou-content" style={iconedBlockStyle}>
          <div className="iconed-block-inner">
            <ShoppingCartHeader {...this.props} />

            <br />

            {!!discount &&
              <p className="sub-heading-2"
                data-testname="price-before-discount"
                dangerouslySetInnerHTML={{
                  __html: i18n.priceWithDiscount
                    .replace('%{price}', priceBeforeDiscount)
                    .replace('%{discount_price}', discount)
                    .replace('%{sign}', currencyType)
                    .replace('%{sign}', currencyType)
                }}></p>}

              {finalPrice > 0 && <h3 className="color-brand"
                data-testname="price-after-discount"
                dangerouslySetInnerHTML={{__html: i18n.price
                    .replace('%{price}', finalPrice)
                    .replace('%{sign}', currencyType)
              }}></h3>}

            {this.renderPayNow()}

          </div>
        </div>
      </div>
    );
  }
});

export default class Page extends React.Component {
  render() {
    return (
      <Layout
        Header={() => <div />}
        Content={ShoppingCartPage}
      />
    );
  }
}

function mapStateToProps(state) {
  const discount = state.$$cart.get('discount');

  return {
    bundleName: state.$$cart.get('bundle_name'),
    bundleId: state.$$cart.get('bundle_id'),
    bundlePolicy: state.$$cart.get('bundle_policy'),
    discount,
    finalPrice: state.$$cart.get('price_after_discount'),
    currencyType: state.$$cart.get('currency_type').match(/[^\s()]+/)[0],
    form: state.$$cart.get('form'),
    i18n: state.$$i18n.getIn(['students', 'cart']).toJS(),
    isExplicitSchool: state.$$school.get('explicit'),
    orderId: state.$$cart.get('order_id'),
    payNowUrl: state.$$layout.getIn(['urls', 'paynow']),
    priceBeforeDiscount: state.$$cart.get('price_before_discount'),
    schoolId: state.$$cart.get('school_id'),
    schoolName: state.$$school.get('name'),
    signedIn: state.$$account.get('signedIn'),
    teacherId: state.$$cart.get('teacher_id'),
    teacherName: state.$$cart.get('teacher_name'),
    couponName: state.$$cart.get('coupon_name'),
    couponDiscount: state.$$cart.get('coupon_discount'),
    bundleAdded: state.$$layout.get('bundle_added'),
    discountedPrice: state.$$cart.get('discounted_price'),
  };
}
